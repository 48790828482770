import axios from 'axios';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401 && !window.location.href.includes('find-doctor')) {
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } 
    return Promise.reject(error);
  }
);

export default axios;