import React from "react"
import ReactDOM from 'react-dom';
import { createRoot } from "react-dom";
import * as Sentry from "@sentry/react";

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';


// // project imports
import * as serviceWorker from './serviceWorker';
import App from './App';
import { store, persister } from './store'

// // style + assets

import './assets/scss/style.scss';
import config from './config';

// ==============================|| REACT DOM RENDER  ||============================== //
if (window.location.href.includes('dr.orthero') || window.location.href.includes('dr.ortohero')) {
  Sentry.init({
    dsn: "https://746366edfbef7ed67f2473e7d87e6f75@o273267.ingest.sentry.io/4506471995342848",
    ignoreErrors: ['googletag', /^Exact Match Message$/],
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["https://dr.orthero.com.tr"],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: "production",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}



ReactDOM.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

//?  failed to execute insertbefore on node react on safari sorunun çözümü bu şekilde olabildiğine dair bir bilgi edindim.
// window.onload = function() {
//     ReactDOM.render(
//       <Provider store={store}>
//           <PersistGate loading={null} persistor={persister}>
//               <BrowserRouter basename={config.basename}>
//                   <App />
//               </BrowserRouter>
//           </PersistGate>
//       </Provider>,
//       document.getElementById('root')
//     );
//   }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
