import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, Snackbar, Button } from '@mui/material';
import './AxiosConfig'

import Routes from './routes';
import themes from './themes';
import Locales from './ui-component/Locales';
import NavigationScroll from './layout/NavigationScroll';
import { JWTProvider } from './contexts/JWTContext';

const App = () => {
    const customization = useSelector((state) => state.customization);
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const checkZoomLevel = () => {
        const zoomLevel = window.devicePixelRatio * 100;
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    
        if (isMac) {
            // Adjust the zoom level for Mac devices
            if (zoomLevel >= 250) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        } else {
            // For non-Mac devices, consider zoom level of 100
            if (zoomLevel > 100) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        }
    
        // Close the zoom warning after 10 seconds
        setTimeout(() => {
            setOpen(false);
        }, 10000);
    };

    useEffect(() => {
        window.addEventListener('resize', checkZoomLevel);
        checkZoomLevel();
        return () => {
            window.removeEventListener('resize', checkZoomLevel);
        }
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <Locales>
                    <NavigationScroll>
                        <JWTProvider>
                            <>
                                <Routes />
                                <Snackbar 
                                    open={open} 
                                    
                                    onClose={handleClose}
                                    message="Please Press Ctrl + 0 or Cmd + 0"
                                   
                                />
                            </>
                        </JWTProvider>
                    </NavigationScroll>
                </Locales>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;